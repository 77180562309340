.o-news-article-gallery {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > a {
    @extend %full-banner;
    display: block;
    flex: 1 1 33.33%;
    height: 150px;
    border: 3px solid $white;

    @include tablet {
      height: 300px;
    }

    &:first-child {
      flex-basis: 100%;
      height: 300px;

      @include tabletDown {
        border-left: none;
        border-right: none;
      }

      @include tablet {
        height: 570px;
      }
    }

    &:nth-child(2) {
      @include tabletDown {
        border-left: none;
      }
    }

    &:nth-child(4) {
      @include tabletDown {
        border-right: none;
      }
    }

    &:nth-child(n+5) {
      display: none;
    }
  }
}
