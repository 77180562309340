.o-sub-nav {
  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 30px;
    }

    &-link {
      @include transition(color .3s ease);
      display: block;
      padding: 15px 0;
      font-size: 20px;

      &:not(.is-active) {
        color: $sub-nav-color;
      }

      &:hover {
        color: lighten($sub-nav-color, 15%);
      }

      &.is-active {
        text-decoration: underline;

        &:hover {
          color: $white;
        }
      }
    }
  }
}
