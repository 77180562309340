%absoluteFull {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

%absoluteTop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

%absoluteBot {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
