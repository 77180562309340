.o-header {
  @include transition(background-color .5s ease);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  background-color: $white;

  .admin-bar & {
    top: 28px;
  }

  @include laptopDown {
    @include transition(background-color .5s ease);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9;

    &:not(.is-scrolled) {
      body:not(.has-banner-white) & {
        background-color: transparent;

        .o-logo__img.is-black,
        .o-lang,
        .o-nav {
          display: none;
        }

        .o-logo__img.is-white {
          display: block;
        }

        .c-hamburger span {
          background: $white;
        }

        .o-header__sub-nav {
          opacity: 0;
          pointer-events: auto;
        }
      }
    }
  }

  &__main {
    position: relative;
    z-index: 9;
    background-color: $white;
    @include transition(background-color .3s ease);

    .scroll-header-alt:not(.has-banner-white) & {
      background-color: transparent;
    }
  }

  .scroll-header-alt & {
    &:not(.is-scrolled) {
      background-color: transparent;

      .o-logo__img.is-black {
        display: none;
      }

      .o-lang,
      .o-nav {
        opacity: 0;
        pointer-events: none;
      }

      .o-logo__img.is-white {
        display: block;
      }

      .c-hamburger span {
        background: $white;
      }

      .o-header__sub-nav {
        opacity: 0;
        transform: translateY(-100%);
      }
    }

    &.is-scrolled {
      .o-header__main {
        background-color: $white;
      }
    }
  }

  .p-academy.scroll-header-alt &,
  .p-space.scroll-header-alt & {
    &:not(.is-scrolled) {
      .o-logo__img.is-black {
        display: block;
      }

      .o-logo__img.is-white {
        display: none;
      }

      .c-hamburger span {
        background: $black;
      }
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 14px 0;
    justify-content: space-between;

    @include laptop {
      padding: 14px 0;
    }

    > * {}
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__left {
    @include laptopDown {
      flex-direction: column;
      align-items: flex-start;
    }

    .o-logo {

      &__img {
        &.is-white {
          display: none;
        }
      }
    }
  }

  &__right {}

  &__sub-nav {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    background-color: $black;
    transform: translateY(0);
    pointer-events: auto;
    @include transition(transform .3s ease);

    @include laptopDown {
      display: none;
    }

    @include laptop {
      .is-scrolled-down & {
        transform: translateY(-100%);
        pointer-events: none;
      }

      .is-scrolled-up & {
        transform: translateY(0);
        pointer-events: auto;
      }
    }

    .scroll-header-alt & {
      @include transition(opacity .5s ease, transform .3s ease);
      opacity: 1;
      pointer-events: auto;
    }

    // In addition to have same space on the left side as main navigation, we are putting invisible logo which size is same as above
    .o-logo {
      opacity: 0;
      pointer-events: none;
      height: 0;
    }

    // @Denis: Here you control on which pages sub-menu would be visible
    // TODO: in PHP you can just put condition should you load "o-header__sub-nav" element on page or not, in that case, this is not needed anymore
    body:not(.p-space) & {
      display: none !important;
    }
  }
}
