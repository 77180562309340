.o-footer {
  padding: 60px 0 20px;
  background-color: $white;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include tablet {
      flex-direction: row;
      align-items: center;
    }

    > * {
      margin-bottom: 20px;
    }
  }
}
