.c-txt {
  &--xsm {
    font-size: 12px;
  }

  &--sm {
    font-size: 18px;
  }

  &--smp {
    font-size: 14px;

    @include tablet {
      font-size: 16px;
    }

    @include laptop {
      font-size: 18px;
    }

    @include desktop {
      font-size: 20px;
    }
  }

  &--md {
    font-size: 22px;

    @include tablet {
      font-size: 26px;
    }

    @include laptop {
      font-size: 30px;
    }
  }
  
  &--lg {
    font-size: 40px;
  }

  &--xl {
    font-size: 18px;

    @include tablet {
      font-size: 24px;
    }

    @include laptop {
      font-size: 38px;
    }

    @include desktop {
      font-size: 48px;
    }
  }

  &-dash {
    position: relative;
    padding-bottom: 20px;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60px;
      border-top: 2px solid $black;
    }
  }
}

p {
  &.c-txt {
    &--sm,
    &--smp {
      line-height: $line-height-xl;
    }
  }
}
