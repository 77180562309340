.o-lang {
  @include laptopDown {
    display: none;
  }

  @include laptop {
    margin-left: 2em;
    @include transition(opacity .3s ease);
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    display: inline-flex;
    align-items: center;

    &:not(:first-child) {
      position: relative;

      &:before {
        content: '/';
      }
    }
  }

  &__link {
    padding: 5px;
    font-weight: $font-weight-medium;
  }
}
