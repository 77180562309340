// Forms
.form {
  &-control {
    height: $form-element-height;
    font-weight: $font-weight-regular;
    outline: none;
    cursor: text !important;
    
    &,
    &:focus {
      color: $color-text-neutral;
      border: none;
      box-shadow: none;
    }
  }
}

input.form-control {
  &.has-icon {
    @extend %cursor-pointer;
    @extend %no-repeat;
    @include transition(all .3s ease);
    padding-right: 35px;
    background-size: 20px;
    background-position: calc(100% - 5px) 50%;

    &:focus {
      background-size: 26px;
    }

    &.has-icon--search {
      background-image: url("../media/icons/magnifier-light-grey.svg") !important;
    }
  }
  
  &[readonly] {
    &.readonly-normal {
      background-color: $white;
      cursor: default !important;
      
      &.curs-point {
        cursor: pointer !important;
      }
    }
  }
}

input.form-control,
textarea.form-control {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $form-placeholder-color;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $form-placeholder-color;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $form-placeholder-color;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $form-placeholder-color;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea.form-control {
  width: 100%;
  resize: vertical;
}
