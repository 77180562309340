// Font Weight
$font-weight-black: 900;
$font-weight-heavy: 800;
$font-weight-heading: 700;
$font-weight-semiBold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-thin: 100;


// Font Size
$body-font-size: 16px;
$small-font-size: .8rem;
$medium-font-size: 1.1rem;
$large-font-size: 1.5rem;
$xlarge-font-size: 2rem;


// Line height
$line-height-default: 1.1;
$line-height-lg: 1.2;
$line-height-lgp: 1.3;
$line-height-xl: 1.4;


// Letter spacing
$letter-spacing-default: 0;


// Font Family
$google-font-primary: 'Lexend Deca';
$google-font-secondary: 'Inter';

$font-sans-serif:             -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base:            $google-font-primary, $font-sans-serif;
$font-family-heading:         $google-font-primary, $font-sans-serif;
$font-family-code:            courier new, Courier, monospace;
