.o-social-list {
  display: flex;
  align-items: center;
  justify-content: center;

  .o-main-menu & {
    justify-content: flex-start;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 40px;

      .o-main-menu & {
        @include tabletDown {
          font-size: 20px;
        }

        @include laptopDown {
          margin-right: 30px;
        }
      }
    }

    [class*="icon-"] {
      @include transition(color .3s ease);
      font-size: 50px;

      .o-main-menu & {
        @include tabletDown {
          font-size: 30px;
        }

        @include laptopDown {
          font-size: 40px;
        }
      }
    }

    a {
      &:hover {
        color: rgba($black, .7);
      }
    }
  }

  &.is-circle {
    .o-social-list__item {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50px;
        background-color: $black;
        z-index: -1;
      }

      [class*="icon-"] {
        @include transition(color .3s ease);
        font-size: 22px;
        color: $white;
      }

      a {
        height: 22px;
      }
    }
  }
}
