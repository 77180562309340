.c-preformat {
  &,
  * {
    font-size: 1.1rem;
    line-height: 1.5;
  }

  > *:first-child {
    margin-top: 0;
  }

  .has-sm-font-size {
    font-size: $small-font-size;
  }

  .has-md-font-size {
    font-size: $medium-font-size;
  }

  .has-lg-font-size {
    font-size: $large-font-size;
  }

  .has-xl-font-size {
    font-size: $xlarge-font-size;
  }

  ul,
  ol {
    margin: 0 0 1rem;
    list-style-position: inside;
  }

  ul {
    list-style-type: none;
    margin: 1.5rem 0;

    > li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 20px;

      &:before {
        content: "";
        position: absolute;
        top: 8px;
        left: 14px;
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 10px;
        background-color: $brand-primary;
      }
    }
  }

  ol {
    padding-left: 0;
    list-style-type: decimal;

    ol {
      padding-left: 1rem;
      margin-top: 1rem;
    }
  }

  a {
    color: $color-text-neutral;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }

  em {
    font-style: italic;
  }

  blockquote {
    display: inline-block;
    position: relative;
    margin: 1rem 0 1.94rem;
    padding: 0 25px;
    font-size: 1.12rem;
    font-family: $font-family-heading;
    font-style: italic;

    &:before,
    &:after {
      content: "";
      background-repeat: no-repeat;
      background-size: 17px 15px;
      width: 17px;
      height: 15px;
      position: absolute;
    }
  }

  p {
    margin: 1.5rem 0;
  }

  //h1 { @extend .c-ttl--lg }
  //h2 { @extend .c-ttl--mdp }
  //h3 { font-size: $mediumPlus-font-size - .250; }
  //h4 { font-size: $mediumPlus-font-size - .375; }
  //h5 { font-size: $mediumPlus-font-size - .5; }
  //h6 { font-size: $mediumPlus-font-size - .625; }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2rem 0 1rem;
    line-height: 1.4;
    font-weight: $font-weight-heading;
    color: $color-text-neutral;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border: 1px solid #999;
    border-spacing: 0;

    thead {
      border-bottom: 1px solid #999;
    }

    tr td {
      border: 1px solid #999;
      border-top: 0;
      border-left: 0;
      padding: 0.5rem 1rem;
    }
  }

  iframe {
    max-width: 100%;
  }

  figure {
    margin: 1.5rem 0;
  }

  img {
    display: block;
    width: 100%;
  }

  .wp-block-image {
    &.size-full {
      @include laptop {
        margin-right: -20%;
      }
      @include desktop {
        margin-right: -50%;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .wp-block-media-text {
    display: flex;
    gap: 2rem;
    align-items: center;
    flex-direction: row;
    margin: 1.5rem 0;
    @include laptop {
      margin-right: -20%;
      gap: 4rem;
    }
    @include desktop {
      margin-right: -50%;
      gap: 6rem;
    }
    .wp-block-media-text__media {
      width: 60%;
      flex-grow: 0;
      flex-shrink: 0;
    }
    .wp-block-media-text__content {
      flex-grow: 1;
      padding: 0;
    }
  }
}
