.o-programs-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-width: 2px 0 0 0;

  @include tablet {
    border-width: 2px 0 0 2px;
  }

  &,
  &__item {
    border-style: solid;
    border-color: $black;
  }

  &__item {
    display: flex;
    flex: 1 0 100%;
    border-width: 0 2px 2px 2px;

    @include tablet {
      flex: 0 0 50%;
      border-width: 0 2px 2px 0;
    }

    @include laptop {
      flex: 0 0 33%;
    }

    @include desktop {
      flex: 0 0 25%;
    }

    @include tabletDown {
      max-width: 100%;
    }

    &.is-load-more {
      align-items: flex-end;
      padding-top: 20px;
      padding-bottom: 5px;
      border-width: 0;

      @include tablet {
        padding-top: 0;
        padding-left: 30px;
      }

      button {
        padding: 10px 10px 5px 0;
      }
    }

    &-note {

    }

    &-title {
      margin: 15px 0 20px;
      hyphens: auto;

      @include tabletDown {
        margin-bottom: 0;
      }
    }

    &-info {
      margin: 0 0 0;
      line-height: $line-height-lgp;

      @include tabletDown {
        display: none;
      }
    }

    a {
      position: relative;
      display: flex;
      padding: 30px;

      @include tabletDown {
        padding: 30px 15px;
      }

      &:before {
        @include transition(opacity .3s ease);
        content: '';
        position: absolute;
        top: 15px;
        right: 15px;
        bottom: 15px;
        left: 15px;
        background-color: $black;
        opacity: 0;
        z-index: -1;
      }

      &:hover {
        &:before {
          opacity: 1;
        }

        .icon-arrow-right {
          color: $white;
          transform: translateX(0);
        }

        .o-programs-list__item__article-content {
          &:before {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }

          > * {
            color: $white;
          }
        }
      }
    }

    &__article {
      position: relative;
      padding-bottom: 50px;

      @include tabletDown {
        padding-bottom: 35px;
      }

      &-content {
        position: relative;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 10; /* number of lines to show */
        line-clamp: 10;
        -webkit-box-orient: vertical;

        > * {
          @include transition(color .3s ease);
        }
      }

      .c-arrow-link {
        position: absolute;
        bottom: -10px;
        left: 0;

        .icon-arrow-right {
          @include transition(color .3s ease);
        }
      }
    }
  }

  &.has-three .o-programs-list {

    &__item {
      flex: 1 0 100%;

      @include tablet {
        flex: 1 0 50%;
      }

      @include laptop {
        flex: 1 0 33%;
      }

      &__article {
        &-content {
          -webkit-line-clamp: 12; /* number of lines to show */
          line-clamp: 12;
        }
      }
    }
  }
}
