// Link
.c-link {
  color: inherit;
  text-decoration: none;

  &:hover {
    @extend %decoration-underline;
  }

  &-eff {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 0;
      height: 2px;
      background-color: $brand-primary;
      @include transition(width .5s ease);
    }

    &:hover:after {
      width: 100%;
    }

    &.cl-white:after {
      background-color: $white;
    }

    &--active:after {
      width: 100%;
    }
  }

  &.is-rev {
    @extend %decoration-underline;

    &:hover {
      text-decoration: none;
    }
  }

  &.is-read-more {
    .icon-chevron-down {
      transform: scaleY(.8);
    }
  }

  //
  //&.-clean {
  //  text-decoration: none!important;
  //}
  //
  //&-arrow {
  //  > [class^="icon-"] {
  //    padding-bottom: 3px;
  //    padding-left: 10px;
  //    font-size: .75em; //12px
  //    transform: translateX(0);
  //    @include transition(transform .3s ease);
  //  }
  //
  //  &:hover {
  //    > [class^="icon-"] {
  //      transform: translateX(5px);
  //    }
  //  }
  //}
}

button.c-link,
button.c-link-eff {
  padding: 0;
  border: 0;
  background-color: transparent;
}

button.c-link {
  &:hover {
    text-decoration: none;
  }

  &.has-underline {
    &:hover {
      text-decoration: underline;
    }
  }
}

.c-arrow-link {
  position: relative;

  &:hover {
    .icon-arrow-right {
      transform: translateX(20px);
    }
  }

  &.is-back {
    &:hover {
      .icon-arrow-right {
        transform: translateX(-20px) rotate(180deg);
      }
    }

    .icon-arrow-right {
      margin-right: 30px;
      margin-left: 0;
      transform: rotate(180deg);
    }
  }

  &.c-txt--sm {
    .icon-arrow-right {
      font-size: 26px;
    }
  }

  &.c-ttl--mdp {

    &.fw-b {
      @include tabletDown {
        font-weight: $font-weight-regular;
      }
    }

    .icon-arrow-right {
      font-size: 34px;

      @include mobilePlus {
        font-size: 50px;
      }

      @include tablet {
        font-size: 60px;
      }

      @include laptop {
        font-size: 80px;
      }

      @include desktop {
        font-size: 90px;
      }
    }
  }

  &,
  .icon-arrow-right,
  .icon-arrow-right:before {
    display: inline-flex;
    align-items: center;
  }

  .icon-arrow-right {
    @include transition(transform .3s ease);
    margin-left: 30px;
    transform: translateX(0);

    &,
    &:before {
      height: 40px;
    }
  }
}
