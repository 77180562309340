%full-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

%bg-center {
  background-position: center;
}

%bg-right {
  background-position: right;
}

%no-repeat {
  background-repeat: no-repeat;
}

%repeat {
  background-repeat: repeat;
}

%repeat-x {
  background-repeat: repeat-x;
}

%repeat-y {
  background-repeat: repeat-y;
}

%bg-cover {
  background-size: cover;
}

%bg-contain {
  background-size: contain;
}
