/***************
  Helper classes for typography
***************/
.txt-upper  {
  text-transform: uppercase;
}

.txt-lower  {
  text-transform: lowercase;
}

.txt-underline {
  text-decoration: underline;
}

.txt-cap {
  //text-transform: capitalize !important;
}

.txt-init {
  //text-transform: none !important;
}

.lh-def {
  //line-height: $line-height-default;
}

.lh-xl {
  //line-height: $line-height-xl;
}

.is-strike {
  //text-decoration: line-through;
}

.hyph {
  hyphens: auto;
}


/***************
  Helper classes for colors
***************/
.cl-prim {
  //color: $brand-primary;
}

.cl-sec {
  //color: $brand-secondary;
}

.cl-white {
  color: $white;
}

.cl-black {
  //color: $black;
}

.cl-neutral {
  color: $color-text-neutral;
}

.cl-red {
  //color: $red;
}

.cl-green {
  //color: $green;
}


/***************
  Helper classes for background colors
***************/
.bg-white {
  //background-color: $white;
}

.bg-green {
  //background-color: $green;
}

.bg-red {
  //background-color: $red;
}

.bg-prim {
  //background-color: $brand-primary;
}

.bg-sec {
  //background-color: $brand-secondary;
}

.bg-dark-grey {
  background-color: $bg-dark-grey;
}


/***************
  Helper classes for border
***************/
.is-rounded {
  //border-radius: $general-border-radius;
}

.b1 {
  //border: 1px solid $border-grey;
}

.b1-prim {
  //border: 1px solid $brand-primary;
}

.b2-prim {
  //border: 2px solid $brand-primary;
}

.b1-sec-l {
  //border: 1px solid $brand-secondary-light;
}


/***************
  Helper classes for widths and heights
***************/
.w-100 {
  width: 100%;
}

img.w-100 {
  height: auto;
}

.h-100 {
  height: 100%;
}

.h-175 {
  //height: 175px;
}

.h-340 {
  //height: 340px;
}

.mw-180 {
  //max-width: 180px;
}

.mw-350 {
  //max-width: 350px;
}

.mw-450 {
  //max-width: 450px;
}

.mw-630 {
  max-width: 630px;
}

.mw-660 {
  max-width: 660px;
}

.mw-820 {
  max-width: 820px;
}

.mw-950 {
  max-width: 950px;
}

.mw-1000 {
  //max-width: 1000px;
}

.mh-60 {
  max-height: 60px;
}

.min-h-0 {
  //min-height: 0;
}

.min-w-60 {
  //min-width: 60px;
}

.wh-20 {
  //width: 20px;
  //height: 20px;
}

.wh-60 {
  //width: 60px;
  //height: 60px;
}


/***************
  Helper classes for visibility per breakpoint
***************/
.visible-laptop {
  @include laptopDown {
    display: none !important;
  }
}

.hidden-laptop {
  @include laptop {
    display: none !important;
  }
}

.visible-tab-des {
  @include tabletDown {
    //display: none !important;
  }
}

.visible-mob-only {
  @include tablet {
    //display: none !important;
  }
}

.visible-mobile-tablet {
  @include laptop {
    display: none !important;
  }
}

.visible-mobile {
  @include tablet {
    display: none !important;
  }
}

.hidden-mobile {
  @include tabletDown {
    display: none !important;
  }
}


/***************
  Helper classes for box-shadow
***************/
.box-sh {
  //box-shadow: $box-shadow-default;
}


/***************
  Helper classes for flex positioning
***************/
.flex-middle {
  //display: flex;
  //align-items: center;
  //justify-content: center;
}

.p-relative {
  position: relative;
}


/***************
  Helper classes for transform
***************/
.rt180 {
  //transform: rotate(180deg);
}


/***************
  Helper classes for padding and margin
***************/
.p-md-down-0 {
  @include tabletDown {
    padding: 0 !important;
  }
}

.p-lg-down-0 {
  @include laptopDown {
    padding: 0 !important;
  }
}
