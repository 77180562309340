.o-mobile-tablet-accordion {
  margin-bottom: 40px;

  &:not(.no-border) {
    border-bottom: 1px solid $black;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    font-size: 30px;

    @include laptop {
      display: none;
    }

    &,
    &.c-link {
      padding: 10px 0;
    }

    .icon-chevron-down {
      @include transition(transform .3s ease);
      transform: rotate(0);
    }

    &.is-open {
      .icon-chevron-down {
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    padding-bottom: 30px;

    @include laptopDown {
      display: none;
    }

    .o-mobile-tablet-accordion__button {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
}
