$general-border-radius: 4px;

$header-link-icon-size: 16px;
$header-link-border-radius: $general-border-radius;
$header-link-drop-space: 20px;
$header-link-drop-arrow: 20px;
$header-link-drop-arrow-border: 1px;
$header-link-drop-slider-arrow-border: 2px;

$header-border-style: 1px solid rgba($black, .2);

$form-element-height: 38px;

$drop-arrow-border-radius: $general-border-radius;
$drop-arrow-width: 14px;
$drop-arrow-arrow-border: 1px;

$brand-icon-height: 57px;

$section-space: 80px;

$box-shadow-default: 0 5px 10px -5px rgba($black, .2);
$box-shadow-top-default: -6px -6px 10px -6px rgba($black, .3);