.o-trio-grid {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  &__item {
    position: relative;
    flex: 0 0 100%;
    height: 430px;
    overflow: hidden;
    z-index: 1;
    padding: 0 20px;

    @include tablet {
      flex: 0 0 50%;
    }

    @include laptop {
      flex: 0 0 33.33%;
    }

    &-link {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: flex-end;
      justify-content: center;
    }

    &-bg-image,
    &-title {
      @include transition(transform .3s ease);
      transform: scale(1);
    }

    &-bg-image {
      @extend %full-banner;
      z-index: -1;

      &,
      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &:before {
        @include transition(opacity .3s ease);
        content: '';
        background-color: rgba($black, .3);
        opacity: 1;
      }
    }

    &:hover {
      .o-trio-grid__item-bg-image {
        transform: scale(1.05);

        &:before {
          opacity: 0;
        }
      }

      .o-trio-grid__item-title {
        transform: scale(.95);
      }
    }

    &-content {}
  }
}
