.o-section {
  &__header {
    padding: 20px 0;

    @include tablet {
      padding: 36px 0;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .c-ttl--md {
        font-size: 36px !important;
      }
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;

    &.has-mobile-tablet-reversed {
      > * {
        &:nth-child(1) {
          @include laptopDown {
            order: 2;
          }
        }

        &:nth-child(2) {
          @include laptopDown {
            order: 1;
          }
        }
      }
    }

    &.has-mobile-tablet-title-in-overlay {
      .c-ttl--lgp {
        @include laptopDown {
          font-size: 50px;
        }
      }

      p.c-txt--sm {
        &.fw-b {
          @include laptopDown {
            font-weight: $font-weight-regular;
          }
        }
      }

      .o-section__body__item {

        &.is-image {

          .o-hero {

            &:before {
              @include laptopDown {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                background-color: rgba($black, .3);
              }
            }
          }
        }

        &.is-content {
          .o-hero {
            @include laptopDown {
              min-height: 0;
            }

            a.c-arrow-link.c-ttl--mdp {
              @include laptopDown {
                font-size: 18px;
              }

              .icon-arrow-right {
                @include laptopDown {
                  margin-left: 15px;
                  font-size: 30px;
                }
              }
            }
          }
        }
      }
    }

    &__item {
      flex: 1;
      flex-basis: 100%;

      &.is-half {
        flex-basis: 100%;
        max-width: 100%;

        @include laptop {
          flex-basis: 50%;
        }
      }

      &.is-third {
        flex-basis: 100%;

        @include laptop {
          flex-basis: 50%;
        }

        @include desktop {
          flex-basis: 33%;
        }
      }
    }
  }
}
