.o-logo {
  @include laptop {
    margin-right: 20px;
  }

  * {
    display: block;
  }

  &__link {}

  &__img {
    width: 37px;
  }
}
