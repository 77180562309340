// Buttons

// Global settings
$btn-padding-y: .5rem;
$btn-padding-x: 1rem;
$btn-border-radius: $general-border-radius;
$btn-font-size: 1.25rem; //20px
$btn-font-weight: $font-weight-semiBold;

// Primary button settings
$btn-primary-bg: $brand-primary;
$btn-primary-bg-hover: darken($btn-primary-bg, 10%);

$btn-primary-color: $white;
$btn-primary-color-hover: $btn-primary-color;

$btn-primary-border-width: 0;
$btn-primary-border-width-hover: $btn-primary-border-width;
$btn-primary-border-style: solid;
$btn-primary-border-style-hover: $btn-primary-border-style;
$btn-primary-border-color: $white;
$btn-primary-border-color-hover: darken($btn-primary-border-color, 10%);


// Secondary button settings
$btn-secondary-bg: $brand-secondary;
$btn-secondary-bg-hover: darken($btn-secondary-bg, 10%);

$btn-secondary-color: $black;
$btn-secondary-color-hover: $btn-secondary-color;

$btn-secondary-border-width: 2px;
$btn-secondary-border-width-hover: $btn-secondary-border-width;
$btn-secondary-border-style: solid;
$btn-secondary-border-style-hover: $btn-secondary-border-style;
$btn-secondary-border-color: $black;
$btn-secondary-border-color-hover: darken($btn-secondary-border-color, 10%);

.btn {
  @include transition();
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: $form-element-height;
  border-radius: $btn-border-radius;
  padding: $btn-padding-y $btn-padding-x;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  -webkit-touch-callout: none;

  &, &:focus {
    outline: 0;
    box-shadow: none;
  }

  @include print {
    display: none;
  }
  
  &[disabled] {}
  
  &.is-wide {
    min-width: 190px;
  }

  &.mobile-full {
    @include tabletDown {
      width: 100%;
    }
  }

  &-primary {
    background-color: $btn-primary-bg;
    color: $btn-primary-color;
    border-width: $btn-primary-border-width;
    border-style: $btn-primary-border-style;
    border-color: $btn-primary-border-color;

    &:hover {
      background-color: $btn-primary-bg-hover;
      color: $btn-primary-color-hover;
      border-color: $btn-primary-border-color-hover;
    }
  }

  &-secondary {
    background-color: $btn-secondary-bg;
    color: $btn-secondary-color;
    border-width: $btn-secondary-border-width;
    border-style: $btn-secondary-border-style;
    border-color: $btn-secondary-border-color;
    border-radius: 0;

    &:hover {
      background-color: $btn-secondary-bg-hover;
      color: $btn-secondary-color-hover;
      border-color: $btn-secondary-border-color-hover;
    }

    &.is-contact {
      padding: 1rem;
      border-color: $white;
      background-color: transparent;
      color: $white;
      font-size: 32px;
      line-height: 1;

      @include tabletDown {
        width: 100%;
      }

      @include tablet {
        padding: .5rem 1.5rem;
        font-size: 40px;
      }

      @include laptop {
        font-size: 50px;
      }

      @include desktop {
        font-size: 64px;
      }

      &:hover {
        border-color: darken($white, 15%);
        color: darken($white, 15%);
      }
    }
  }
  
  &.is-smaller {
    min-height: 40px;
    padding-right: 1.9rem;
    padding-left: 1.9rem;
    font-size: 1rem;
    text-transform: uppercase;
  }
}

button.c-btn:hover {
  cursor: pointer;
}
