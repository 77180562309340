.c-section {
  margin-top: $section-space / 2;
  margin-bottom: $section-space / 2;
  
  @include tablet {
    margin-top: $section-space - 20;
    margin-bottom: $section-space - 20;
  }

  @include laptop {
    margin-top: $section-space;
    margin-bottom: $section-space;
  }
}
