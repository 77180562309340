.o-main-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: $white;
  pointer-events: none;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 40px;
  transform: translate(-112%, 0) scale(1.2);
  @include transition(transform .5s ease);

  .admin-bar & {
    top: 28px;
  }

  &.active {
    transform: translate(0, 0) scale(1);
    pointer-events: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0;
    margin-bottom: 20px;

    @include mobilePlus {}

    @include tablet {
      margin-bottom: 20px;
    }

    @include laptop {
      margin-bottom: 40px;
    }

    @include desktop {
      margin-bottom: 60px;
    }
  }

  &__list {
    &.has-mobile-tablet-separators {

      .o-main-menu__list-item {
        @include laptopDown {
          padding: 20px 0;
          border-bottom: 1px solid $black;
          margin: 0;
        }

        &:first-child {
          @include laptopDown {
            border-top: 1px solid $black;
          }
        }
      }
    }

    &-item {
      margin-bottom: 20px;

      > a {
        display: block;
        font-size: 36px;
        font-weight: $font-weight-medium;

        @include mobilePlus {
          font-size: 40px;
        }

        @include tablet {
          //font-size: 50px;
        }

        @include laptop {
          display: inline-block;
          font-size: 46px;
        }

        @include desktop {
          font-size: 60px;
        }
      }
    }
  }
}
