.c-device-selector {
  display: none;
  
  &.visible {
    &-mobile {
      @include mobileOnly {
        display: block;
      }
    }
    
    &-mobilePlus {
      @include mobilePlusOnly {
        display: block;
      }
    }
    
    &-tablet {
      @include tablet {
        @include laptopDown {
          display: block;
        }
      }
    }
    
    &-laptop {
      @include laptopOnly {
        display: block;
      }
    }
    
    &-desktop {
      @include desktop {
        display: block;
      }
    }
  }
}
