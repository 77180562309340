.o-hero {
  @extend %full-banner;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  min-height: 360px;
  padding-bottom: 20px;
  color: $white;

  @include mobilePlus {
    min-height: 460px;
    padding-bottom: 40px;
  }

  @include tablet {
    min-height: 560px;
    padding-bottom: 60px;
  }

  @include laptop {
    min-height: 660px;
    padding-bottom: 80px;
  }

  @include desktop {
    min-height: 680px;
    padding-bottom: 100px;
  }

  &.is-xs {
    min-height: 440px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;

    @include tablet {
      padding-bottom: 40px;
      padding-right: 40px;
      padding-left: 40px;
    }

    .p-lg-down-0 & {
      @include laptopDown {
        padding-left: 20px;
      }
    }
  }

  &.is-sm {
    min-height: 360px;
    padding-bottom: 30px;

    @include mobilePlus {
      min-height: 400px;
    }

    @include tablet {

    }

    @include laptop {
      min-height: 620px;
      padding-bottom: 50px;
    }

    @include desktop {}

    .swiper-slide & {
      @include mobilePlusDown {
        min-height: 280px;
      }
    }
  }

  .p-design-case-study & {
    &.is-xs,
    &.is-sm {
      @include tabletDown {
        min-height: 280px;
      }
    }
  }

  &.is-md {
    min-height: 360px;
    padding-bottom: 20px;

    @include mobilePlus {
      min-height: 460px;
      padding-bottom: 40px;
    }

    @include tablet {
      min-height: 560px;
      padding-bottom: 60px;
    }

    @include laptop {
      min-height: 660px;
      padding-bottom: 80px;
    }

    @include desktop {
      min-height: 760px;
      padding-bottom: 100px;
    }
  }

  &.is-lg {
    min-height: 670px;
    padding-top: 80px;
    padding-bottom: 60px;

    @include tablet {}

    @include laptop {
      min-height: 870px;
      padding-bottom: 100px;
    }

    &.pb-sm {
      padding-bottom: 60px;
    }

    .p-work & {
      padding-bottom: 60px;
    }

    &.is-lg-mobile-small {
      @include tabletDown {
        min-height: 360px;
      }
    }
  }

  &.has-overlay {
    &:before {
      @extend %absoluteFull;
      content: '';
      z-index: -1;
      background-color: rgba($black, .3);
    }

    &.is-link {
      &:before {
        @include transition(background-color .3s ease);
      }

      &:hover {
        &:before {
          background-color: rgba($black, 0);
        }
      }
    }
  }

  &.has-tablet-mobile-overlay {
    @include laptopDown {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: rgba($black, .3);
      }

      &.is-link {
        &:before {
          @include transition(background-color .3s ease);
        }

        &:hover {
          &:before {
            background-color: rgba($black, 0);
          }
        }
      }
    }
  }

  &.has-block-hover-eff {
    @include transition(border .3s ease);
    border: 0 solid $white;

    &:before {
      @extend %absoluteFull;
      @include transition(background-color .3s ease);
      content: '';
      z-index: -1;
      background-color: rgba($black, 0);

      @include laptopDown {
        background-color: rgba($black, .2);
      }
    }

    > * {
      @include laptop {
        @include transition(opacity .3s ease);
        opacity: 0;
        pointer-events: none;
      }
    }

    &:hover {
      border: 30px solid $white;

      &:before {
        background-color: rgba($black, .2);
      }

      > * {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &.has-block-hover-eff,
  &.has-tablet-mobile-overlay {
    .o-hero__right-container,
    .o-hero__left-container {
      @include tablet {
        padding-left: 20px;
      }

      @include laptop {
        padding-left: 40px;
      }

      .container {
        @include tablet {
          padding-left: 0;
        }
      }
    }
  }

  &.has-centered-content {
    align-items: center;
    padding-bottom: 0;
  }

  &.has-down-content {
    display: flex;
    align-items: flex-end;

    > * {
      flex: 1;
    }
  }

  &.has-up-down-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 40px;
    padding-right: 40px;

    > * {
      width: 100%;
    }
  }

  &.is-gin {
    background-color: $black;
    background-position: center bottom;
    background-size: cover;

    @include desktop {
      background-size: 1600px;
    }
  }

  &.is-event-square {
    width: 270px;
    height: 270px;
    min-height: 0;
    padding: 0;
    border-radius: 100%;

    @include mobilePlus {
      width: 300px;
      height: 300px;
    }

    @include tablet {
      width: 400px;
      height: 400px;
    }
  }

  &.has-dark-grey-gradient-bottom {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 200px;
      pointer-events: none;
      background: rgb(255,255,255);
      background: linear-gradient(0deg,
              rgba($bg-dark-grey, 1) 10%,
              rgba($bg-dark-grey, 0) 100%);
      z-index: -1;
    }
  }

  &.has-mobile-min-height-zero {
    @include tabletDown {
      min-height: 0;
    }
  }

  &.has-mobile-tablet-min-height-zero {
    @include laptopDown {
      min-height: 0;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 60px;

    @include tabletDown {
      flex-direction: column;
      align-items: flex-start;
    }

    @include laptop {
      margin-top: 10px;
    }

    &__item {
      margin-top: 20px;

      @include laptop {
        margin-top: 50px;
      }

      &:first-child {
        @include tabletDown {
          margin-top: 0;
        }
      }

      &:not(:last-child) {
        @include tablet {
          margin-right: 30px;
        }

        @include laptop {
          margin-right: 50px;
        }
      }

      &-link {
        hyphens: auto;
      }
    }
  }

  &__left-container {
    width: 100%;

    @include laptop {
      max-width: 745px;
      margin-left: auto;
    }
  }

  &__right-container {
    width: 100%;

    @include laptop {
      max-width: 745px;
      padding-left: 60px;
    }
  }

  // Titles in hero
  .c-ttl--lgp,
  .c-ttl--mdp,
  .c-ttl--mdpp {
    @include mobilePlusDown {
      font-size: 30px;
    }

    @include mobilePlus {
      @include tabletDown {
        font-size: 40px;
      }
    }

    @include tablet {
      @include laptopDown {
        font-size: 50px;
      }
    }
  }

  .c-ttl--lgp.c-ttl--lgp--mobile-large {
    @include mobilePlusDown {
      font-size: 40px;
    }

    @include mobilePlus {
      @include tabletDown {
        font-size: 50px;
      }
    }
  }
}
