.o-nav {
  @include laptopDown {
    position: absolute;
    top: 100%;
    right: -$grid-gutter-width-half;
    left: -$grid-gutter-width-half;
    max-height: 0;
    overflow: hidden;
    //border-bottom: 2px solid $brand-primary;
    background-color: $black;
    pointer-events: none;
    @include transition(max-height .8s ease);
  }

  @include laptop {
    @include transition(opacity .3s ease);
  }

  &.active {
    max-height: 500px;
    pointer-events: auto;
    @include transition(max-height 1.4s ease);
  }

  &__list {
    display: flex;
    align-items: center;

    @include laptopDown {
      flex-direction: column;
      padding: 60px 0 10px;
    }
  }

  &__item {
    @include laptopDown {
      display: block;
      width: 100%;
    }

    &:not(:last-child) {
      @include laptop {
        padding-right: 1rem;
        border-right: 2px solid $brand-primary;
        margin-right: 1rem;
      }
    }
  }

  &__link {
    padding: 5px 0;
    font-weight: $font-weight-medium;
    font-size: 20px;

    @include laptopDown {
      display: block;
      width: 100%;
      padding: 20px 0;
      text-align: center;
    }

    &.is-active {
      font-weight: $font-weight-heading;
    }
  }
}
