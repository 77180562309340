/*
  Helper classes for font styles
*/

.fw-l {
  font-weight: $font-weight-light;
}
.fw-r {
  font-weight: $font-weight-regular;

  &-laptop {
    @include laptop {
      font-weight: $font-weight-regular;
    }
  }
}
.fw-m {
  font-weight: $font-weight-medium;
}
.fw-sb {
  //font-weight: $font-weight-semiBold;
}
.fw-b {
  font-weight: $font-weight-heading;
}

.fs-10 {
  //font-size: 10px;
}

.fs-12 {
  //font-size: 12px;
}

.fs-14 {
  //font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  //font-size: 18px;
}

.fs-20 {
  //font-size: 20px;
}

.fs-24 {
  //font-size: 24px;
}

.fs-26 {
  //font-size: 26px;
}

.fs-40 {
  //font-size: 40px;
}
