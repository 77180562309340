.o-awards-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-right: -10px;
  margin-left: -10px;

  @include mobilePlus {
    margin-right: -15px;
    margin-left: -15px;
  }

  @include tablet {
    margin-top: -15px;
  }

  &__item {
    display: flex;
    justify-content: center;
    flex: 0 0 50%;
    padding-top: 20px;

    @include tablet {
      flex: 0 0 12.5%;
      padding-top: 0;
    }

    > * {
      padding: 10px;

      @include mobilePlus {
        padding: 15px;
      }
    }

    &-link {}

    &-logo {
      height: 50px;
      max-height: 50px;

      @include mobilePlus {
        height: 70px;
        max-height: 70px;
      }
    }
  }

  @include tablet {

  }
}
