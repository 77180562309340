.o-space-list {

  &__item {
    padding-bottom: 80px;

    &-button {
      text-align: left;

      &:hover {
        .o-space-list__item-button__text {
          text-decoration: none;
        }
      }

      &.active {
        .icon-chevron-down {
          transform: scaleY(.8) rotate(180deg);
        }
      }

      &__text {

        &.c-ttl--lgp {

          @include tabletDown {
            font-size: 30px;

            @include mobilePlusDown {
              font-size: 22px;
            }
          }
        }
      }

      .icon-chevron-down {
        @include transition(transform .3s ease);
        font-size: 20px;
        transform: scaleY(.8);

        @include mobilePlus {
          font-size: 30px;
        }

        @include tablet {
          font-size: 40px;
        }

        @include laptop {
          font-size: 50px;
        }
      }
    }

    &-content {
      display: none;
    }
  }
}
