html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
  font-family: $font-family-base;
  font-weight: $font-weight-regular;
  font-size: $body-font-size;
  line-height: $line-height-default;
  letter-spacing: $letter-spacing-default;
  color: $color-text-neutral;
}

body {
  &.overlay-opened {
    overflow: hidden;
  }
}

a {
  color: $color-text-neutral;
}

button {
  cursor: pointer;
  font-weight: inherit;
  
  &[disabled] {
    cursor: not-allowed;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-heading;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 0;

  > li {
    font-size: 1rem;
  }
}

a,
a.c-btn {
  text-decoration: none;
}

body {
  display: flex;
  flex-direction: column;

  html.ie & {
    display: block;
  }

  main {
    flex: 1;
  }

  &:not(.scroll-header-alt) main {
    padding-top: 60px;
  }
}

p {
  line-height: $line-height-lg;
}

hr {
  border: 0;
  border-bottom: 1px solid $color-text-neutral;

  &.white {
    border-bottom-color: $white;
  }
}
