.swiper {
  &-wrapper {}

  &-container {
    .swiper-button {
      width: 100px;
      height: 100px;
      color: $white;

      &:after {
        font-size: 80px;
      }

      &.swiper-button-disabled {
        opacity: 0;
      }

      &-prev {
        left: 5%;
      }

      &-next {
        right: 5%;
      }
    }
  }

  &-slide {
    width: 90%;

    @include tablet {
      width: 70%;
    }

    @include laptop {
      width: 85%;
    }
  }
}
