.o-subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include laptop {
    flex-direction: row;
  }

  &__logo {
    margin-bottom: 30px;

    @include laptop {
      padding-right: 50px;
      margin-bottom: 0;
    }

    img {
      display: block;
      width: 160px;

      @include laptop {
        width: 231px;
      }
    }
  }

  &__form {
    margin-bottom: 50px;
    text-align: center;

    @include laptop {
      padding-right: 50px;
      margin-bottom: 0;
      text-align: left;
    }

    @include desktop {
      padding-right: 110px;
    }

    .form-group {
      .form-control {
        @include transition(border-bottom-width .3s ease);
        height: auto;
        padding-left: 0;
        border-radius: 0;
        border-bottom: 1px solid $black;
        text-align: center;

        @include laptop {
          text-align: left;
        }

        &:focus {
          border-bottom-width: 3px;
        }
      }
    }

    .form-control,
    .btn {
      @extend .c-txt--md;
    }

    .btn {
      padding: 14px 30px;
    }
  }

  &__social {
    text-align: center;

    @include laptop {
      text-align: left;
    }

    .o-social-list {
      &__item {
        &:not(:last-child) {
          margin-right: 20px;

          @include laptop {
            margin-right: 30px;
          }

          @include desktop {
            margin-right: 60px;
          }
        }

        [class*="icon-"] {
          font-size: 40px;

          @include laptop {
            font-size: 50px;
          }

          @include desktop {
            font-size: 74px;
          }
        }
      }
    }
  }
}
