.c-date-category-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  hyphens: auto;

  > * {
    margin-bottom: 5px;

    &:first-child:after {
      @include tabletDown {
        content: normal !important;
      }
    }

    &:not(:last-child) {
      &:after {
        content: '|';
        margin: 0 5px;
        font-weight: $font-weight-regular;
      }
    }
  }

  > strong {
    @include tabletDown {
      flex-basis: 100%;
    }
  }

  > span {}
}
