// Titles
.c-ttl {
  margin: 0 0 1em;
  font-size: $body-font-size; //16px
  font-weight: $font-weight-regular;

  &--xsm {}

  &--sm {
    font-size: 24px;
    line-height: 1;
  }

  &--smp {
    font-size: 30px;
    line-height: 1;
  }

  &--md {
    font-size: 36px;
    line-height: 1;
  }
  
  &--mdp {
    font-size: 30px;
    line-height: 1;

    @include tablet {
      font-size: 40px;
    }

    @include laptop {
      font-size: 40px;
    }

    @include desktop {
      font-size: 40px;
    }
  }

  &--mdpp {
    font-size: 50px;
    line-height: 1;
  }

  &--lg {
    font-size: 40px;
    line-height: 1;

    &.is-mobile-small {
      @include tabletDown {
        font-size: 30px;
      }
    }

    @include tablet {
      font-size: 48px;
    }

    @include laptop {
      font-size: 58px;
    }

    @include desktop {
      font-size: 64px;
    }
  }

  &--lgp {
    font-size: 44px;
    line-height: 1;
    hyphens: auto;

    @include tablet {
      font-size: 50px;
    }

    @include laptop {
      font-size: 60px;
    }

    @include desktop {
      font-size: 80px;
    }
  }

  &--xl {
    font-size: 50px;
    line-height: 1;

    @include tablet {
      font-size: 60px;
    }

    @include laptop {
      font-size: 80px;
    }

    @include desktop {
      font-size: 120px;
    }
  }
}
