// Neutral colors
$white: #ffffff;
$black: #000000;


// RGB
$red: #D32542;
$green: #0AB21B;


// Grey
$brand-grey: #363636;


// Brand Primary
$brand-primary: $black;


// Brand Secondary
$brand-secondary: $white;


// Text colors
$color-text-neutral: $brand-primary;
//$color-text-grey: #3D403B;
$form-placeholder-color: #cccccc;
$sub-nav-color: #999999;


// Customs
$bg-dark-grey: #1d1e21;
